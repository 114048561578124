import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../../components/ui/base/layout/header"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import Section from "../../components/ui/base/Section"
import { useTranslation } from "react-i18next"

const GDPRPage = () => {
  const { i18n: l } = useTranslation()
  const data = useStaticQuery(query)
  const GDPRPage = data.strapiGdprPage

  return (
    <>
      <Header
        size={"compact"}
        videoURL={"weshoot-viaggi-fotografici"}
        theme="dark"
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  {GDPRPage.title}
                </Typography>
                <Breadcrumbs
                  elements={[
                    {
                      name: "WeShoot",
                      path: "/",
                    },
                    {
                      name: "GDPR",
                      path: `/gdpr/`,
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section
        variant={"grey"}
        title={GDPRPage.title}
        subtitle={GDPRPage.subtitle}
      >
        <div dangerouslySetInnerHTML={{ __html: GDPRPage.body }} />
      </Section>
    </>
  )
}

export const query = graphql`
  {
    strapiGdprPage {
      title
      subtitle
      body
    }
  }
`

export default GDPRPage
